import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Facebook Audiences On Huge Scale",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-07T16:53:46.000Z",
  "url": "/2019/05/facebook-audiences-on-huge-scale/",
  "categories": ["My Projects"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Description`}</h4>
    <p><strong parentName="p">{`Tech Stack:`}</strong>{` php, mongo On our system we tracked and stored thousands of actions for our users. With all of that useful info we could easily segment the users to groups. For example: `}</p>
    <ul>
      <li parentName="ul">{`Users with items in their cart`}</li>
      <li parentName="ul">{`Users that didn`}{`’`}{`t purchase in the last 30 days`}</li>
      <li parentName="ul">{`Users who visited a specific page that live in the US The above lists can easily get too tens of millions of users. And we need to upload those to `}{`“`}{`Facebook Custom Audiences`}{`”`}{` with their API. ** Facebook API has some limitations (more to come`}{`…`}{`) `}</li>
    </ul>
    <h5>{`What is Facebook Custom Audience? Its Facebook tool for advertisers to pinpoint their ads to a specific audience or advertise to `}{`“`}{`look alike`}{`”`}{` audience (by Facebook algorithms)`}</h5>
    <h5>{`So what is the problem? We wanted to make sure our `}{`“`}{`custom audience`}{`”`}{` list are up to date. That means that every week/day/hour the list has the proper users inside it. As an example we want to make sure to not have users with nothing in their cart and deliver them an add with click to complete their purchase. Facebook API has some limitations as you can only: remove and delete users in a list. When you delete you cant truncate (delete all) because you need to tell them who to delete. And on our system the lists are dynamic so its not like we now who got added to a list or got removed.`}</h5>
    <h5>{`What did we do?`}</h5>
    <ul>
      <li parentName="ul">{`On each upload to Facebook, we saved a snapshot of the upload`}</li>
      <li parentName="ul">{`On the next upload we will compare the snapshots and will only call facebook with the differentiations. example:`}</li>
    </ul>
    <p>{`first upload: user1, user2, user3`}<br parentName="p"></br>{`
`}{`the second upload needed: user1, user4  `}</p>
    <p>{`That means we now have these actions:`}<br parentName="p"></br>{`
`}{`add to Facebook: user4`}<br parentName="p"></br>{`
`}{`remove from Facebook: user2, user3 `}</p>
    <h4>{`How did we do it? We tested several ways to do the comparison: in memory sqlite and loading csv files, using comm tool for getting diffs from sorted lists, plain files loaded into objects on comparing them. The result in the end was we stored the items on Mongo and when we re-synced we updated mongo with another key and got the diffs. Code example will come soon 🙂`}</h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      